.Main-Container {
  background: #fafafa;
  min-height: 100%;
}

.SideBanner {
  display: flex;
  flex-direction: column;
  
  /* min-height: 100vh; */
}
.SideContent {
  padding: 40px 45px 75px;
}

@media screen and (min-width: 975px){
  .SideBanner{
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: 33%; /* Set the width of the sidebar */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    
    top: 0; /* Stay at the top */
    left: 0;
    bottom: 0;
    overflow-y: hidden; /* Disable vertical scroll */
  }
  
  .All-Content{
    margin-left: 33%;
    width: 67%;
    position: static;
    overflow-x: hidden;
    float: none;
    clear: none;
  }
}

.Title {
  font-size: 35px;
  font-family: 'MuseoSans', 'Khanaya', sans-serif;
  color:  #3b80fe;
  display: flex;
  flex-direction: row;
  margin-bottom: 35px;
  justify-items: center;
}

.Subtitle {
  font-size: 24px;
  font-family: 'MuseoSans', 'Khanaya', sans-serif;
  color: #4a4a4a;
  margin:0;
  font-weight: 100;
}

.Body {
  margin-top: 35px;
  font-size: 16px;
  font-family: 'MuseoSans100';
  color: #4a4a4a;
}

.Content-Container {
  padding: 40px 45px 40px;
}

.ContentTitle {
  color:#fafafa;
  font-size: 24px;
  font-family: 'MuseoSans100', 'Khanaya', sans-serif;
  margin: 0;
}
.ContentTitleBlack{
  font-size: 24px;
  font-family: 'MuseoSans100', 'Khanaya', sans-serif;
  color: #4a4a4a;
  padding-bottom: 24px;
  margin: 0;
}

.Content-Border{
  border-left: 1px solid #DDDDDD;

}

.Content1 {
  background: #ff9e9e
}

.ContentImage{
  margin-top: 35px;
  max-width:100%;
  max-height:100%;
  border-width:1px;
  border-style: solid;
  margin-bottom: 35px;
}

.ContentImage1{
  box-shadow: 10px 10px 5px #ff8585;
  border-color: #b92f2f;
}

.ContentImage2{
  box-shadow: 10px 10px 5px #90b199;
  border-color: #48584c;
}

.Content2 {
  background: #b4ddbf
}

.Content3 {
  background: #005ba9
}

.Icon-Container {
  flex-direction: row;
  margin-top: 35px;
}

.Icon {
  margin-right: 40px;
  height:24px;
  margin-bottom: 4px;
  border: 2px solid transparent;
  padding: 2px;
  border-radius: 20%;
}

.Icon:hover {
  border-color: #3b80fe;
}

.Link-Wrapper {
  display:inline-block;
  text-decoration: none;
}

.EmailButton{
  padding:15px 65px;
  position: absolute;
  background: #3b80fe;
  color:#fafafa;
  font-size: 16px;
  font-family: 'MuseoSans100', 'Khanaya', sans-serif;
  border-radius: 100px;
  text-decoration: none;
}

.EmailButton:hover {
  background: #2f66cb;
}

footer{
  text-align: center;
  padding: 35px;
  background: #222222;
  font-family: 'MuseoSans100', 'Khanaya', sans-serif;
  color:#fafafa;
  font-size: 16px;
}



