@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'), url(./Fonts/MuseoSansDisplay-Black.ttf) format('truetype');
  font-family: 'MuseoSans100';
  src: local('MuseoSans100'), url(./Fonts/MuseoSans-100.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

